<template>
  <div>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <a-card>
          <strong>Scan QR Code</strong>
          <a-button
            class="btn btn-outline-primary pull-right"
            @click="addSessions"
          >
            Open Sessions
          </a-button>

          <a-card class="mt-3">
            <center>
              <img
                :src="
                  qrimage != null && qrimage !== ''
                    ? qrimage
                    : 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/images.png?alt=media&token=fa24ebc0-356e-47c7-acb9-137b7330c7fb'
                "
              />
            </center>
          </a-card>
        </a-card>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <a-card>
          <label>Active Sessions: <strong>{{ user.activeSessions }}</strong></label>
          <a-button class="btn btn-outline-primary pull-right" @click="getList(true)">
            Refresh List
          </a-button>
          <a-table
            :key="componentKey"
            class="mt-3"
            size="small"
            :columns="columnDefs"
            :dataSource="dataDefs"
            :pagination="{
              defaultPageSize: 20,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
              hideOnSinglePage: true,
            }"
          >
            <span slot="action" slot-scope="text, record, index">
              <!-- <a
                href="javascript:void(0)"
                @click="sendToForm('Update', record)"
                class="text-warning"
                >Edit</a
              >
            -->
              <a-icon type="check" :class="record.active ? 'text-success' : ''" :style="record.active ? '' : 'cursor: pointer;'" @click="setActiveSession(record.id, index)"></a-icon>
              <a-divider type="vertical"/>
              <a
                href="javascript:void(0)"
                @click="deleteSessions(record)"
                class="text-danger"
                >
                <a-icon type="delete"></a-icon>
                </a
              >
            </span>
          </a-table>
        </a-card>
        <!-- <a-card class="mt-2">
          <div class="row">
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <a-input placeholder="Find Session"></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              <a-button class="btn btn-outline-primary" @click="getList">
                Cari
              </a-button>
            </div>
          </div>
        </a-card> -->
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'ant-design-vue'
import { mapState } from 'vuex'
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      sessionKey: '',
      qrimage: null,
      columnDefs: [
        {
          title: 'Action',
          scopedSlots: {
            customRender: 'action',
          },
        },
        {
          title: 'Session Name',
          dataIndex: 'id',
          // class: 'bg-transparent text-gray-6',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          // class: 'bg-transparent text-gray-6',
        },
      ],
      dataDefs: [],
      componentKey: 0,
    }
  },
  created() {
    this.checkActiveSessionsFromApi()
    this.getList()
    this.sessionKey = this.user.activeSessions
    if (this.sessionKey !== '') {
      this.checkStatusSessions()
    }
  },
  methods: {
    moment,
    async checkActiveSessionsFromApi() {
      var res = await lou.customUrlGet2('sessions/active')
      if (res) {
        this.user.activeSessions = res.sessionId
      }
    },
    refresh() {
      this.getList()
    },
    async setActiveSession(id, index) {
      var obj = {
        sessionId: id,
      }
      var res = await lou.customUrlPost2('sessions/active', obj)
      if (res) {
        this.user.activeSessions = id
        this.dataDefs.forEach(element => {
          element.active = false
        })
        this.dataDefs[index].active = true
        // console.log('this.settings.acti', this.settings.activeSessions)
        // this.componentKey += 1
        this.$message.success('Session ' + id + ' actived.')
      }
    },
    async getList(withNotif = false) {
      if (withNotif) {
        lou.shownotif('Refresh!', 'Data sedang direfresh.')
      }
      var res = await lou.customUrlGet2('sessions')
      if (res) {
        this.dataDefs = []
        res.forEach(element => {
          var nj = {
            ...element,
            active: false,
          }
          this.dataDefs.push(nj)
        })

        if (withNotif) {
          lou.shownotif('Success!', 'Data berhasil direfresh.', 'success')
        }
      }
    },
    async checkStatusSessions() {
      var res = await lou.customUrlGet2(
        'sessions/' + this.sessionKey + '/status',
      )
      if (res) {
        this.getList()
        if (res.status === 'CONNECTED') {
          var delet = await lou.customUrlDelete2(
            'sessions/' + this.sessionKey,
          )
          console.log('delet', delet)
          this.getList()
        } else if (res.status === 'AUTHENTICATED') {
          var that = this
          setTimeout(() => {
            // console.log('this.sessionKey', that.sessionKey)
            var index = that.dataDefs.findIndex((x) => x.id === that.sessionKey)
            console.log('index update', index)
            if (index !== -1) {
              that.dataDefs[index].active = true
              that.componentKey += 1
            }
          }, 1000)
        }
      } else {
        this.getList()
      }
    },
    async addSessions() {
      var res = await lou.customUrlPost2('sessions/add', {
        sessionId: this.settings.currentIp,
      })
      if (res) {
        this.qrimage = res.qr
        this.getList()
        // lou.shownotif('info', '')
      } else {
        // lou.shownotif('Error', 'error')
      }
    },
    deleteSessions() {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.customUrlDelete2(
            'sessions/' + this.sessionKey,
          )
          this.getList()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
