var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-6 col-sm-6 col-md-6 col-lg-6"},[_c('a-card',[_c('strong',[_vm._v("Scan QR Code")]),_c('a-button',{staticClass:"btn btn-outline-primary pull-right",on:{"click":_vm.addSessions}},[_vm._v(" Open Sessions ")]),_c('a-card',{staticClass:"mt-3"},[_c('center',[_c('img',{attrs:{"src":_vm.qrimage != null && _vm.qrimage !== ''
                  ? _vm.qrimage
                  : 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/images.png?alt=media&token=fa24ebc0-356e-47c7-acb9-137b7330c7fb'}})])],1)],1)],1),_c('div',{staticClass:"col-xs-6 col-sm-6 col-md-6 col-lg-6"},[_c('a-card',[_c('label',[_vm._v("Active Sessions: "),_c('strong',[_vm._v(_vm._s(_vm.user.activeSessions))])]),_c('a-button',{staticClass:"btn btn-outline-primary pull-right",on:{"click":function($event){return _vm.getList(true)}}},[_vm._v(" Refresh List ")]),_c('a-table',{key:_vm.componentKey,staticClass:"mt-3",attrs:{"size":"small","columns":_vm.columnDefs,"dataSource":_vm.dataDefs,"pagination":{
            defaultPageSize: 20,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
            hideOnSinglePage: true,
          }},scopedSlots:_vm._u([{key:"action",fn:function(text, record, index){return _c('span',{},[_c('a-icon',{class:record.active ? 'text-success' : '',style:(record.active ? '' : 'cursor: pointer;'),attrs:{"type":"check"},on:{"click":function($event){return _vm.setActiveSession(record.id, index)}}}),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.deleteSessions(record)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)}}])})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }